<template>
  <!-- 角色管理页面 -->
  <div class="role-manage" v-loading="allLoading">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :autoLoad="false"
      :row-key="getRowKeys"
      noEmptyText="el.common.noData"
    >
      <template slot="formBtn">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.search") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="handleAdd()"
          >{{ $t("el.common.new") }}</el-button
        >
      </template>
      <template slot="otherHandle" slot-scope="{ row }">
        <el-button
          v-if="row.isDefault !== 1"
          type="text"
          @click="handleEdit(row)"
          >{{ $t("el.common.edit") }}</el-button
        >
        <el-button
          v-if="row.isDefault !== 1"
          type="text"
          @click="handleAuth(row)"
          >{{ $t("el.saas.menuAuth") }}</el-button
        >
        <el-button type="text" @click="handleAllot(row)">{{
          $t("el.saas.assigningUsers")
        }}</el-button>
        <el-button
          v-if="row.isDefault !== 1"
          type="text"
          @click="handleDelete(row)"
          >{{ $t("el.common.delete") }}</el-button
        >
      </template>
    </xm-table>
    <c-dialog
      v-if="dialogVisible"
      ref="dialogRoleRef"
      :formItems="formDialogItems"
      :formOptions="dialogFormOptions"
      :visible="dialogVisible"
      :optionsData="optionsData"
      @submit-form="submitForm"
      @close-dialog="closeDialog"
    ></c-dialog>
  </div>
</template>

<script>
import CDialog from "@/components/biz-components/CDialog/index.vue";
import {
  roleAddApi,
  rolePageApi,
  roleDeleteApi,
} from "@/api/manage/role-manage";
export default {
  components: {
    CDialog,
  },
  data() {
    return {
      allLoading: false,
      newFormOptions: {},
      dialogVisible: false, // 新增编辑弹窗是否显示
      dialogType: "add", // 弹窗类型 add edit
      isShowOptions: [
        { label: "启用", value: "1" },
        { label: "禁用", value: "0" },
      ],
    };
  },
  computed: {
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "input",
          prop: "roleId",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.saas.role.roleCode"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "roleName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.saas.role.roleName"),
          clearable: true,
        },
        {
          itemType: "select",
          prop: "isShow",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.saas.role.roleStatus"),
          "popper-append-to-body": false,
          clearable: true,
          options: this.isShowOptions,
        },
      ];
    },
    // 表格列配置
    columns() {
      return [
        {
          label: this.$t("el.saas.role.roleCode"),
          prop: "roleId",
        },
        {
          label: this.$t("el.saas.role.roleName"),
          prop: "roleName",
        },
        {
          label: this.$t("el.saas.role.roleStatus"),
          prop: "isShowName",
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          fix: "right",
          width: "230px",
        },
      ];
    },
    // 弹窗表单项
    formDialogItems() {
      let items = [
        {
          itemType: "input",
          prop: "roleName",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.saas.role.roleName"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          itemStyle: "width:100%;",
          formStyle: "width:100%;box-sizing: border-box;",
          maxlength: 30,
        },
        {
          itemType: "select",
          prop: "isShow",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.saas.role.roleStatus"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          options: this.isShowOptions,
          itemStyle: "width:100%;",
          formStyle: "width:100%;box-sizing: border-box;",
        },
      ];
      return items;
    },
    // 弹窗表单配置
    dialogFormOptions() {
      return {
        rules: {
          roleName: [
            {
              required: true,
              message: this.$t("el.saas.role.roleNameRequired"),
              trigger: "blur",
            },
          ],
          isShow: [
            {
              required: true,
              message: this.$t("el.saas.role.roleStatusRequired"),
              trigger: "blur",
            },
          ],
        },
      };
    },
    // 弹窗配置
    optionsData() {
      return {
        dialogTitle:
          this.dialogType === "add"
            ? this.$t("el.saas.role.roleAdd")
            : this.$t("el.saas.role.roleEdit"),
      };
    },
  },
  methods: {
    // 筛选
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    // 重置
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },
    // 获取数据
    fatchMethod(params) {
      return rolePageApi(params);
    },
    //
    getRowKeys(row) {
      return row.id;
    },
    // 新增
    handleAdd() {
      this.dialogType = "add";
      this.dialogVisible = true;
    },
    // 编辑
    handleEdit(row) {
      this.dialogType = "edit";
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.dialogRoleRef.queryParams = {
          roleName: row.roleName,
          roleDesc: row.roleDesc,
          roleId: row.roleId,
          isShow: row.isShow,
        };
      });
    },
    // 编辑、新增提交
    submitForm(refFormName, dialogForm) {
      if (!this.allLoading) {
        this.allLoading = true;
        refFormName.validate(async (valid) => {
          if (valid) {
            if (this.dialogType === "add") {
              // 新增
              roleAddApi({
                ...dialogForm,
              })
                .then(() => {
                  this.$message.success(this.$t("el.classManage.addSuccess"));
                  this.closeDialog();
                  this.query();
                })
                .catch(() => {
                  this.allLoading = false;
                });
            } else {
              // 编辑
              await roleAddApi({
                ...dialogForm,
              })
                .then(() => {
                  this.$message.success(
                    this.$t("el.studentManage.editSuccess")
                  );
                  this.closeDialog();
                  this.query();
                })
                .catch(() => {
                  this.allLoading = false;
                });
            }
            // let params = {
            //   questionnaireTerm:
            //     dialogForm.academicYearAndSemester.questionnaireTerm,
            //   questionnaireYear:
            //     dialogForm.academicYearAndSemester.questionnaireYear,
            //   ...dialogForm,
            // };
            // delete params.academicYearAndSemester;
            // if (dialogForm.id) {
            //   // 编辑
            //   await updateQuestionnaire({ ...params, id: dialogForm.id }).then(
            //     () => {
            //       this.$message.success(
            //         this.$t("el.studentManage.editSuccess")
            //       );
            //       this.closeDialog();
            //       this.query();
            //     }
            //   );
            // } else {
            //   // 新增
            //   await addQuestionnaire(params).then(() => {
            //     this.$message.success(this.$t("el.classManage.addSuccess"));
            //     this.closeDialog();
            //     this.query();
            //   });
            // }
            this.allLoading = false;
          } else {
            this.allLoading = false;
            return false;
          }
        });
      }
    },
    // 关闭dialog
    closeDialog() {
      this.dialogVisible = false;
    },
    // 功能权限
    handleAuth(row) {
      this.$router.push({
        path: "/rolemanage/permission",
        query: {
          id: row.roleId,
        },
      });
    },
    // 分配用户
    handleAllot(row) {
      this.$router.push({
        path: "/rolemanage/allotUser",
        query: {
          roleId: row.roleId,
        },
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("", this.$t("el.common.deleteConfirm"), {
        center: true,
        customClass: "el-message-box--confirm el-message-box--danger",
        message: this.$createElement(
          "div",
          {
            class: "el-message-box--delete",
          },
          this.$t("el.saas.role.roleDeleteConfirm")
        ),
      }).then(() => {
        roleDeleteApi(row.roleId).then(() => {
          this.$message.success(this.$t("el.classManage.deleteSuccess"));
          this.query();
        });
      });
    },
  },
  mounted() {
    this.query();
  },
};
</script>

<style lang="less" scoped>
.role-manage {
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 10px;
  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
  /deep/ .cell {
    .el-button {
      padding: 9px 5px;
      & + .el-button {
        margin-left: 0;
      }
    }
  }
}

/deep/ .el-dialog {
  text-align: left;
  .el-form-item__content {
    width: calc(100% - 120px);
    .el-input {
      width: 100%;
    }
  }
}
</style>
