<template>
  <div class="app-container">
    <div class="menu-top">
      <span class="current-role">当前角色：</span>
      <span class="current-role">{{ roleName }}</span>
    </div>
    <div class="menu-btn">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-arrow-left"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <div v-loading="loading" class="menu-box">
      <el-col :sm="10" class="card-box-col">
        <el-card class="card-box">
          <div class="card-label tc">未分配用户</div>
          <div>
            <el-input
              v-model="undist.staffName"
              placeholder="输入账号或姓名搜索"
              @keyup.native="search($event, 0)"
            />
          </div>

          <div class="user-list">
            <!-- <el-scrollbar style="overflow-y: auto; height: calc(100vh - 84px - 94px - 126px - 60px); "> -->
            <div v-loading="undistLoading">
              <el-table
                :data="undist.list"
                border
                @selection-change="
                  (res) => {
                    undist.checked = res;
                  }
                "
              >
                <el-table-column type="selection" align="center" />
                <el-table-column label="手机号" prop="userAccount" />
                <el-table-column label="姓名" prop="staffName" />
              </el-table>
            </div>

            <!-- </el-scrollbar> -->
          </div>
        </el-card>
      </el-col>
      <el-col :sm="4" class="card-box-col">
        <div class="card-box tb" style="padding-top: 0">
          <div class="td" style="vertical-align: middle; text-align: center">
            <div class="item-btn">
              <el-button
                :loading="distLoading"
                class="filter-item"
                size="medium"
                type="primary"
                @click="add"
              >
                添加分配
                <i class="el-icon-arrow-right" />
              </el-button>
            </div>
            <div class="item-btn cancelbtn">
              <el-button
                :loading="undistLoading"
                class="filter-item"
                plain
                size="medium"
                @click="remove"
              >
                <i class="el-icon-arrow-left" />
                取消分配
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :sm="10" class="card-box-col">
        <el-card class="card-box">
          <div class="card-label card-label2 tc">已分配用户</div>
          <div>
            <el-input
              v-model="dist.staffName"
              placeholder="输入账号或姓名搜索"
              @keyup.native="search($event, 1)"
            />
          </div>

          <div class="user-list">
            <!-- <el-scrollbar style="height:calc(100vh - 84px - 94px - 126px - 60px);overflow-y: auto; overflow-x: hidden;"> -->
            <div v-loading="distLoading">
              <el-table
                :data="dist.list"
                border
                @selection-change="
                  (res) => {
                    dist.checked = res;
                  }
                "
              >
                <el-table-column type="selection" align="center" />
                <el-table-column label="手机号" prop="userAccount" />
                <el-table-column label="姓名" prop="staffName" />
              </el-table>
            </div>

            <!-- </el-scrollbar> -->
          </div>
        </el-card>
      </el-col>
    </div>
  </div>
</template>
<script>
import tools from "@/utils/tools";
import {
  getRoleNameApi,
  roleUserSaveApi,
  roleUserList,
} from "@/api/manage/role-manage";
export default {
  name: "allotUser",
  data() {
    return {
      roleName: "",
      listLoading: false,
      dist: {
        // 已分配用户
        pagin: {
          roleId: this.$route.query.roleId,
          limit: 50,
          page: 1,
          offset: 0,
        },
        staffName: "", // 搜索字段
        checked: [],
        list: [],
      },
      undist: {
        // 未分配用户
        pagin: {
          roleId: this.$route.query.roleId,
          limit: 50,
          page: 1,
          offset: 0,
        },
        staffName: "",
        checked: [],
        list: [
          // { id: 1, name: "张三", number: "100011" }
        ],
      },
      saveData: {
        // 已分配用户
        add: [], // 添加了
        remove: [], // 移除了
      },
      distLoading: false, // 分配中
      undistLoading: false, // 取消分配中
      loading: false, // 加载中
    };
  },
  created() {
    var roleId = this.$route.query.roleId;
    if (!roleId) {
      return this.$router.go(-1);
    }
    this.loading = true;
    Promise.all([this.getUndist(), this.getDist()])
      .then(() => {
        return getRoleNameApi(roleId); // 获取角色名称
      })
      .then((res) => {
        this.roleName = res.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    lock(type) {
      setTimeout(() => {
        this["_lock" + type] = false;
      }, 50);
    },
    getUndist(key) {
      // 未分配
      this.undistLoading = true;
      return roleUserList(0, this.undist.pagin, this.undist.staffName, 1)
        .then((res) => {
          // var data = res.data.records || [];
          // if (this.undist.pagin.page === 1) {
          //   this.undist.list = data;
          // } else {
          //   this.undist.list = this.undist.list.concat(data);
          // }
          // this.$nextTick(() => {
          //   this._el && (this._el.scrollTop = this._scrollTop);
          // });
          // if (data.length == this.undist.pagin.limit) {
          //   this.lock(0);
          // }
          this.undist.list = res.data || [];
          this.undistLoading = false;
        })
        .catch(() => {
          // this.lock(0)
          this.undistLoading = false;
        });
    },
    getDist(key) {
      this.distLoading = true;
      return roleUserList(1, this.dist.pagin, this.dist.staffName)
        .then((res) => {
          // var data = res.data.records || [];
          // if (this.dist.pagin.page === 1) {
          //   this.dist.list = data;
          // } else {
          //   this.dist.list = this.dist.list.concat(data);
          // }
          // this.$nextTick(() => {
          //   this._el && (this._el.scrollTop = this._scrollTop);
          // });
          // if (data.length == this.dist.pagin.limit) {
          //   this.lock(1);
          // }
          this.dist.list = res.data || [];
          this.distLoading = false;
        })
        .catch(() => {
          // this.lock(1)
          this.distLoading = false;
        });
    },
    search(ev, type) {
      if (ev.which == 13) {
        if (type) {
          this.dist.pagin.page = 1;
          this.dist.pagin.offset = 0;
          this.dist.list = [];
          this.getDist();
        } else {
          this.undist.pagin.page = 1;
          this.undist.pagin.offset = 0;
          this.undist.list = [];
          this.getUndist();
        }
      }
    },
    scroll(ev, type) {
      // type:0未分配
      var el = ev.target || ev.srcElement;
      var max = el.scrollHeight - Math.max(el.clientHeight, el.offsetHeight);
      if (max - el.scrollTop <= 0.5) {
        if (this["_lock" + type]) {
          return;
        }
        this["_lock" + type] = true;
        this._el = el;
        this._scrollTop = el.scrollTop;
        type ? this.dist.pagin.page++ : this.undist.pagin.page++;
        type
          ? (this.dist.pagin.offset =
              (this.dist.pagin.page - 1) * this.dist.pagin.limit)
          : (this.undist.pagin.offset =
              (this.undist.pagin.page - 1) * this.undist.pagin.limit);
        type ? this.getDist() : this.getUndist();
      }
    },
    add() {
      if (!(this.undist.checked && this.undist.checked.length > 0)) {
        return this.$message.warning("请至少选择一个未分配用户");
      }
      this.distLoading = true;
      this.updateDistData(
        this.undist.checked.map((item) => item.id),
        []
      )
        .then(() => {
          this.distLoading = false;
          for (var i in this.undist.checked) {
            var m = this.undist.checked[i];
            this.dist.list.unshift(m);
            tools.deleteOrFindArr(this.undist.list, m, "", 0);

            tools.deleteOrFindArr(this.saveData.remove, m.id, "", 0);
            var res = tools.deleteOrFindArr(this.saveData.add, m.id, "", 0, 1);
            if (res.length < 1) {
              this.saveData.add.push(m.id);
            }
          }
          this.refresh(0);
          this.refresh(1);
        })
        .catch(() => {
          this.distLoading = false;
        });
    },
    remove() {
      if (!(this.dist.checked && this.dist.checked.length > 0)) {
        return this.$message.warning("请至少选择一个已分配用户");
      }
      this.undistLoading = true;
      this.updateDistData(
        [],
        this.dist.checked.map((item) => item.id)
      )
        .then(() => {
          this.undistLoading = false;
          for (var i in this.dist.checked) {
            var m = this.dist.checked[i];
            this.undist.list.unshift(m);
            tools.deleteOrFindArr(this.dist.list, m, "", 0);

            tools.deleteOrFindArr(this.saveData.add, m.id, "", 0);
            var res = tools.deleteOrFindArr(
              this.saveData.remove,
              m.id,
              "",
              0,
              1
            );
            if (res.length < 1) {
              this.saveData.remove.push(m.id);
            }
          }
          this.refresh(0);
          this.refresh(1);
        })
        .catch(() => {
          this.undistLoading = false;
        });
    },
    /**
     * 更新分配数据
     * @param {Array} add 添加分配
     * @param {Array} remove 移除分配
     */
    updateDistData(add, remove) {
      return roleUserSaveApi({
        add,
        remove,
        roleId: this.$route.query.roleId,
      }).then((res) => {
        this.saveData.add = [];
        this.saveData.remove = [];
        this.$message.success("保存成功");
      });
    },
    /**
     * 刷新列表
     * - 0 (未分配列表)
     * - 1 (已分配列表)
     * @param {Number} type 刷新什么列表
     */
    refresh(type) {
      var name = type ? "dist" : "undist";
      // 刷新列表的方法
      var fn = type ? this.getDist : this.getUndist;
      // 列表的参数
      var param = type ? this.dist : this.undist;
      // 滚动容器
      var el = this.$refs[type ? "dist" : "undist"];
      if (param.list.length) {
        var offset = param.pagin.offset;
        var limit = param.pagin.limit;
        param.pagin.offset = 0;
        param.pagin.limit = param.list.length;
        var restore = () => {
          param.pagin.offset = offset;
          param.pagin.limit = limit;
        };
        param.list = [];
        fn()
          .then(() => {
            restore();
          })
          .catch(restore);
      } else {
        param.pagin.offset = 0;
        fn();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.app-container {
  background: #fff;
  border-radius: 2px;
  padding: 0 25px 30px;
}
.menu-top {
  padding: 21px 0 18px;
  .current-role {
    color: rgba(50, 50, 50, 1);
    font-size: 14px;
  }
  .current-role:last-child {
    color: rgba(112, 114, 118, 1);
  }
}
.menu-btn {
  padding: 0 0 17px;
}
.menu-box {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
  .card-box {
    height: calc(100vh - 84px - 94px - 60px);
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    padding-top: 58px;
    box-sizing: border-box;
    &.card-auto {
      height: auto;
    }
    .card-label {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background: #6049ff;
      padding: 18px 0 20px;
      color: #fff;
      z-index: 2;
      font-size: 14px;
      text-align: center;
      // height: 58px;
    }
    .card-label2 {
      background: #6049ff;
    }
    .card-list {
      margin: -20px;
      > .card-item {
        padding: 6px 10px;
        cursor: pointer;
        font-size: 14px;
        &:hover {
          background: #e6e6e6;
        }
      }
    }
    .user-list {
      height: calc(100vh - 84px - 94px - 126px - 80px);
      overflow-y: auto;
      overflow-x: hidden;
      margin: 20px -20px 0;
      padding: 0 20px;
    }
    .item-btn {
      padding: 20px 0;
      width: 100%;
      margin: 0 auto;
      > button {
        width: 100px;
        padding: 0;
        height: 34px;
        font-size: 12px;
        border-radius: 3px;
      }
    }
    .cancelbtn {
      > button {
        height: 32px;
      }
    }
  }
}
</style>
