<template>
  <!-- 测试动态页面 -->
  <div>
    <div class="rolePermission">
      <div class="rolePermission-main">
        <div class="f-c rolePermission-main-title">
          <div class="f-l rolePermission-main-title-label">当前角色：</div>
          <div class="f-l rolePermission-main-title-text">{{ roleName }}</div>
        </div>
        <div class="f-c btns">
          <el-button type="primary" @click="goBack">
            <img
              class="rolePermission-main-btnicon"
              src="@/assets/images/icon/Rectangle 48 Copy 12@2x(1).png"
            />返回</el-button
          >
          <el-button type="primary" @click="saveData">
            <i
              ><img
                class="rolePermission-main-btnicon"
                src="@/assets/images/icon/Rectangle 48 Copy 12@2x.png" /></i
            >保存</el-button
          >
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            v-loading="loading"
            style="width: 100%"
            row-key="menuId"
            border
            default-expand-all
            :header-cell-class-name="headerStyle"
            :cell-class-name="cellStyle"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="menuName" label="菜单列表" width="300">
            </el-table-column>
            <el-table-column label="菜单权限" width="100">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.hasParent == '-1' &&
                    scope.row.children &&
                    scope.row.children.length > 0
                  "
                  style="text-align: center"
                >
                  <img
                    :src="
                      !(scope.row.children || []).some(
                        (value) =>
                          !value.checked ||
                          (value.buttons || []).some((ele) => !ele.checked)
                      )
                        ? selectedCheckboxIcon
                        : scope.row.children.some((value) => value.checked)
                        ? halfCheckIcon
                        : normalCheckboxIcon
                    "
                    class="f-l"
                    @click="check(scope.row.checked, scope.row, '0')"
                  />
                </div>
                <div
                  v-else-if="
                    scope.row.hasParent == '-1' &&
                    (!scope.row.children || scope.row.children.length <= 0)
                  "
                  style="text-align: center"
                >
                  <img
                    :src="
                      scope.row.checked
                        ? selectedCheckboxIcon
                        : normalCheckboxIcon
                    "
                    class="f-l"
                    @click="check(scope.row.checked, scope.row, '0')"
                  />
                </div>
                <div
                  v-else-if="scope.row.buttons && scope.row.buttons.length > 0"
                >
                  <img
                    :src="
                      scope.row.buttons.every((value) => value.checked)
                        ? selectedCheckboxIcon
                        : scope.row.buttons.some((value) => value.checked)
                        ? halfCheckIcon
                        : normalCheckboxIcon
                    "
                    class="f-l"
                    @click="check(scope.row.checked, scope.row, '1')"
                  />
                </div>
                <div v-else>
                  <img
                    :src="
                      scope.row.checked
                        ? selectedCheckboxIcon
                        : normalCheckboxIcon
                    "
                    class="f-l"
                    @click="check(scope.row.checked, scope.row, '1')"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="按钮权限">
              <template slot-scope="scope">
                <div class="f-c table-list">
                  <div
                    class="f-c f-l table-list-item"
                    v-for="(item, i) in scope.row.buttons"
                    :key="i"
                  >
                    <img
                      :src="
                        item.checked ? selectedCheckboxIcon : normalCheckboxIcon
                      "
                      class="f-l"
                      @click="
                        item.checked = !item.checked;
                        check(item.checked, scope.row, '2');
                      "
                    />
                    <div class="f-l">{{ item.btnName }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import normalCheckboxIcon from "@/assets/images/icon/checkbox-normal.png";
import selectedCheckboxIcon from "@/assets/images/icon/checkbox-selected.png";
import halfCheckIcon from "@/assets/images/icon/checkbox-half.png";
// import { meanPermission, getRoleName, saveRoleAuth } from "@/api/system/role";
import tools from "@/utils/tools";
import { list2tree } from "@/utils";
import {
  getRoleNameApi,
  roleMenuApi,
  saveRoleAuthApi,
  getRoleMenuByRoleId,
} from "@/api/manage/role-manage";
export default {
  name: "rolePermission",
  // 注册组件
  components: {},
  // 定义数据
  data() {
    return {
      tableData: [
        // {
        //   id: 1,
        //   menu: '学科资源管理',
        //   ischeck: false,
        //   all: true,
        //   list: [],
        //   children: [{
        //     id: 4,
        //     menu: '课程属性',
        //     ischeck: false,
        //     list: [
        //       {
        //         id: 41,
        //         menu: '课程属性',
        //         ischeck: false,
        //       },
        //       {
        //         id: 42,
        //         menu: '课程属性',
        //         ischeck: true,
        //       }
        //     ],
        //   },
        //   {
        //     id: 7,
        //     menu: '课程管理',
        //     ischeck: false,
        //     list: [
        //       {
        //         id: 71,
        //         menu: '课程属性',
        //         ischeck: false,
        //       },
        //       {
        //         id: 72,
        //         menu: '课程属性',
        //         ischeck: false,
        //       }
        //     ]
        //   }]
        // },
        // {
        //   id: 222,
        //   menu: '管理',
        //   ischeck: false,
        //   all: true,
        //   list: [],
        //   children: [{
        //     id: 223,
        //     menu: '课程属性',
        //     ischeck: false,
        //     list: [
        //       {
        //         id: 225,
        //         menu: '课程属性',
        //         ischeck: false,
        //       },
        //       {
        //         id: 226,
        //         menu: '课程属性',
        //         ischeck: true,
        //       }
        //     ],
        //   }, {
        //     id: 333,
        //     menu: '课程管理',
        //     ischeck: false,
        //     list: [
        //       {
        //         id: 3334,
        //         menu: '课程属性',
        //         ischeck: false,
        //       },
        //       {
        //         id: 3335,
        //         menu: '课程属性',
        //         ischeck: false,
        //       }
        //     ]
        //   }]
        // }
      ],
      normalCheckboxIcon: normalCheckboxIcon,
      selectedCheckboxIcon: selectedCheckboxIcon,
      halfCheckIcon: halfCheckIcon,
      roleName: "", // 当前角色
      loading: false, // 加载中
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 创建完成钩子
  created() {
    this.getData();
    this.getName();
  },
  // 挂在完成钩子
  mounted() {},
  // 方法定义
  methods: {
    check(item, row, flg) {
      // flg 0根节点 1子节点 2 按钮权限节点
      if (flg == "0") {
        const isHalfCheck =
          !row.children?.every((value) => value.checked) &&
          row.children?.some((value) => value.checked);
        if (isHalfCheck) {
          // 半选时 在此勾选操作为全选
          item = !item;
        }
        row.checked = !item;
        if (row.children && row.children.length > 0) {
          for (let i = 0; i < row.children.length; i++) {
            row.children[i].checked = !item;
            for (let j = 0; j < row.children[i].buttons.length; j++) {
              row.children[i].buttons[j].checked = !item;
            }
          }
        }
      } else {
        if (flg == "1") {
          row.checked = !item;
          if (row.buttons && row.buttons.length > 0) {
            for (let i = 0; i < row.buttons.length; i++) {
              row.buttons[i].checked = !item;
            }
          }
        } else {
          row.checked = !!(
            row.buttons &&
            row.buttons.length > 0 &&
            row.buttons.some((value) => value.checked)
          );
        }
        this.tableData.forEach((item, index, arr) => {
          if (item.children && item.children.length > 0) {
            return (item.checked = !!item.children.some(
              (value) => value.checked
            ));
          }
        });
      }
    },
    goBack() {
      // 返回
      this.$router.push({
        path: "/rolemanage",
      });
    },
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "bskCell";
    },
    getData() {
      // 获取列表数据
      this.loading = true;
      getRoleMenuByRoleId(this.$route.query.id)
        .then((res) => {
          this.tableData = list2tree(res.data, "menuId", "hasParent", true);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getChildTree(data) {
      // 转换树形结构
      var arr = [];
      arr = tools.treeDataChangeField(arr, {
        data: {
          buttons: "list",
        },
        treeNodes: "buttons",
      });
      return arr;
    },
    getName() {
      // 获取默认角色
      getRoleNameApi(this.$route.query.id)
        .then((res) => {
          this.roleName = res.data;
        })
        .catch(() => {});
    },
    isTopMenu(item) {
      // 是否一级菜单
      return item.hasParent == "-1";
    },
    saveData() {
      // 保存
      var btnIds = [];
      var menuIds = [];
      this.tableData.forEach((item, index, arr) => {
        if (item.checked) {
          menuIds.push(item.menuId);
        }
        if (item.children && item.children.length > 0) {
          item.children.forEach((item2, index2, arr2) => {
            if (item2.checked) {
              menuIds.push(item2.menuId);
            }
            if (item2.buttons && item2.buttons.length > 0) {
              item2.buttons.forEach((item3, index3, arr3) => {
                if (item3.checked) {
                  btnIds.push(item3.btnId);
                }
              });
            }
          });
        }
      });
      this.loading = true;
      saveRoleAuthApi({
        btnIds,
        menuids: menuIds,
        roleId: this.$route.query.id,
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
          this.loading = false;
          this.$router.go(-1);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.rolePermission {
  background: #fff;
  border-radius: 2px;
  padding: 0 25px 0;
  .btns {
    padding: 20px 0 21px;
    /deep/.el-button span {
      margin-left: 15px;
    }
  }
  &-main {
    padding: 28px 0 34px;
    &-title {
      font-size: 14px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      &-label {
        color: rgba(50, 50, 50, 1);
        margin-right: 1px;
      }
      &-text {
        color: rgba(112, 114, 118, 1);
      }
    }
    &-btnicon {
      width: 14px;
      position: absolute;
      top: 11px;
      left: 18px;
    }
    button {
      font-size: 12px;
      width: 82px;
      position: relative;
    }
  }
  .table {
    font-size: 14px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(112, 114, 118, 1);
    line-height: 20px;
    &-list {
      &-item {
        height: 20px;
        line-height: 20px;
        margin-right: 30px;
        img {
          margin: 2px 6px 0 0 !important;
          cursor: pointer;
        }
      }
    }
    /deep/.el-icon-arrow-right {
      font-size: 14px;
      line-height: 14px;
    }
    /deep/.el-icon-arrow-right:before {
      content: "\e723";
    }
    /deep/.el-table__expand-icon--expanded .el-icon-arrow-right:before {
      content: "\e722";
    }
    /deep/.el-table__expand-icon--expanded {
      transform: rotate(0deg);
      margin-left: -14px;
    }
    img {
      width: 16px;
      margin: 0px 32px 0;
      cursor: pointer;
    }
  }

  /deep/.bskHeader {
    background-color: #f5f6f7 !important;
    color: #313131;
    padding: 0 !important;
    text-align: center !important;
    height: 56px;
  }
  /deep/.bskCell {
    text-align: center !important;
    padding: 8px 0 !important;
  }
  /deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #f1f6ff;
  }
  /deep/.el-table__row .el-button {
    box-shadow: none;
    border: none;
    color: #4375ef;
    border-color: transparent;
    background-color: transparent;
  }
  /deep/.el-button + .el-button {
    margin-left: 26px;
  }
  /deep/.el-table th.is-leaf,
  .el-table td {
    border-bottom: 1px solid #ebebeb;
  }
  /deep/.el-table--border td {
    height: 57px;
  }
  /deep/.el-table .cell {
    display: inline-block;
    line-height: 20px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
}
</style>
