/**
 * 角色管理
 */
import axios from "@/plugins/axios.js";

/**
 * 角色新增编辑
 * @return {Promise}
 */
export function roleAddApi(data) {
  return axios.post("/role/addOrUpdateRole", data);
}

/**
 * 角色列表
 * @return {Promise}
 */
export function rolePageApi(params) {
  return axios.get("/role/getRolePage", { params });
}

/**
 * 角色删除
 * @return {Promise}
 */
export function roleDeleteApi(roleId) {
  return axios.get(`/roleAuth/deleteRole/${roleId}`);
}

/**
 * 查角色名
 * @return {Promise}
 */
export function getRoleNameApi(roleId) {
  return axios.get(`/roleAuth/getRoleName/${roleId}`);
}

/**
 * 获取所有菜单项
 * @return {Promise}
 */
export function roleMenuApi(params) {
  return axios.get("/role/getRoleList", params);
}

/**
 * 获取某角色下已授权的菜单项
 * @return {Promise}
 */
export function getRoleMenuByRoleId(roleId) {
  return axios.get(`/roleAuth/selectRoleAuth/${roleId}`);
}

/**
 * 给角色授权菜单
 * @return {Promise}
 */
export function saveRoleAuthApi(queryParams) {
  return axios.post(`/roleAuth/saveRoleAuth`, queryParams);
}

/**
 * 获取角色列表下拉
 * @return {Promise}
 */
export function getRoleListApi(queryParams) {
  return axios.get("/role/getRoleList");
}

/**
 * 获取用户远程搜索下拉列表
 * @return {Promise}
 */
export function getRemoteUserListApi() {
  return axios.get("/roleUser/getUserByTenantId");
}

/**
 * 角色批量添加/移除用户
 * @return {Promise}
 */
export function roleUserSaveApi(data) {
  return axios.post("/roleUser/addUser2Role", data);
}

/**
 * 当前角色，已授权/未授权的用户列表
 * @return {Promise}
 */
export function roleUserList(type, pagin, key, source) {
  return axios.get("/roleUser/selectUserByRoleId", {
    params: {
      type: type,
      ...pagin,
      staffName: key,
      source: source,
    },
  });
}
