/* eslint-disable */
export default {
  findArr(h, f, c) {
    var l = [],
      k = 0;
    var e = Object.prototype.toString.call(f) === "[object Array]";
    if (e) {
      k = f.length;
    } else {
      for (var g in f) {
        k++;
      }
    }
    if (k < 1) {
      return l;
    }
    for (var g in h) {
      var b = h[g];
      if (e) {
        if (k == 1 && !c) {
          l.push(b[f[0]]);
        } else {
          var n = {};
          for (var d in f) {
            n[f[d]] = b[f[d]];
          }
          l.push(n);
        }
      } else {
        var a = 1,
          n = {};
        for (var d in f) {
          if (typeof f[d] == "function" && !f[d](b[d], b)) {
            a = 0;
            break;
          } else {
            n[d] = b[d];
            if (k == 1 && !c) {
              a = 0;
              l.push(b[d]);
            }
          }
        }
        a && l.push(n);
      }
    }
    return l;
  },
  deleteOrFindArr(k, g, c, a, l, e) {
    c = c || "children";
    l = Object.prototype.toString.call(l) === "[object Array]" ? l : l ? [] : l;
    if (Object.prototype.toString.call(k) === "[object Array]") {
      for (var h = 0; h < k.length; h++) {
        var d = k[h],
          b = 1;
        if (g && typeof g == "object") {
          for (var f in g) {
            if (
              typeof g[f] == "function"
                ? !g[f](f, k[h][f], k[h])
                : k[h][f] != g[f]
            ) {
              b = 0;
              break;
            }
          }
        } else {
          if (d != g) {
            b = 0;
          }
        }
        if (b) {
          if (l) {
            l.push(d);
          } else {
            k.splice.apply(k, [h, 1].concat(e || []));
            h--;
          }
          if (a) {
            continue;
          } else {
            break;
          }
        }
        g && typeof g == "object" && this.deleteOrFindArr(d, g, c, a, l);
      }
    } else {
      if (k && typeof k == "object") {
        Object.prototype.toString.call(k[c]) === "[object Array]" &&
          this.deleteOrFindArr(k[c], g, c, a, l);
      }
    }
    return l;
  },
  //复制对象
  clone(obj, deep) {
    if (!obj) {
      return obj;
    }
    if (
      obj.tagName &&
      obj.nodeType == 1 &&
      typeof obj.cloneNode == "function"
    ) {
      return obj.cloneNode(deep);
    }
    var res;
    if (Object.prototype.toString.call(obj) === "[object Array]") {
      res = [];
      for (var i = 0; i < obj.length; i++) {
        res.push(this.clone(obj[i], deep));
      }
    } else {
      if (obj && typeof obj == "object") {
        res = {};
        for (var i in obj) {
          if (!deep) {
            res[i] = obj[i];
          } else {
            res[i] = this.clone(obj[i], deep);
          }
        }
      } else {
        res = obj;
      }
    }
    return res;
  },
  treeDataChangeField(arrData, settings) {
    //对象数组或对象字段key修改
    settings = settings || {};
    settings.refer = settings.refer === undefined ? 1 : settings.refer;
    var data;
    if (Object.prototype.toString.call(arrData) === "[object Array]") {
      //数组
      data = [];
      for (var i in arrData) {
        data.push(this.treeDataChangeField(arrData[i], settings));
      }
    } else if (arrData && typeof arrData === "object") {
      //对象
      var opt = settings.data;
      data = {};
      for (var i in settings.refer ? arrData : opt) {
        if (arrData[i] !== undefined) {
          settings.contain && (data[i] = arrData[i]); //包含原数据所有字段，可能会被覆盖
          if (typeof opt[i] == "function") {
            var obj = opt[i](i, arrData[i], arrData);
            if (Object.prototype.toString.call(obj) === "[object Array]") {
              data[obj[0]] = obj[1];
            } else if (obj && typeof obj === "object") {
              for (var j in obj) {
                data[j] = obj[j];
              }
            }
          } else {
            var isArr =
              Object.prototype.toString.call(settings.treeNodes) ===
              "[object Array]";
            data[opt[i] ? opt[i] : i] = (
              isArr
                ? settings.treeNodes.indexOf(i) >= 0
                : !settings.treeNodes || settings.treeNodes === i
            )
              ? this.treeDataChangeField(arrData[i], settings)
              : arrData[i];
          }
        }
      }
    } else {
      data = arrData;
    }
    return data;
  },
  deleteOrFindArr: function (arrData, obj, children, _all, find, replaceArr) {
    //删除或查找数组中有某个对象的元素. _all代表是否全部删除
    children = children || "children"; //obj可以是基本数据类型.replaceArr在删除的位置添加的数据
    find =
      Object.prototype.toString.call(find) === "[object Array]"
        ? find
        : find
        ? []
        : find; //默认0
    if (Object.prototype.toString.call(arrData) === "[object Array]") {
      for (var i = 0; i < arrData.length; i++) {
        var m = arrData[i],
          flg = 1;
        if (obj && typeof obj == "object") {
          for (var j in obj) {
            if (
              typeof obj[j] == "function"
                ? !obj[j](j, m[j], m, arrData, i)
                : m[j] != obj[j]
            ) {
              flg = 0;
              break;
            }
          }
        } else {
          if (m != obj) {
            flg = 0;
          }
        }
        if (flg) {
          if (find) {
            find.push(m);
          } else {
            if (typeof replaceArr == "function") {
              replaceArr = replaceArr(i, m, arrData);
            }
            replaceArr =
              Object.prototype.toString.call(replaceArr) == "[object Array]"
                ? replaceArr
                : replaceArr || [];
            arrData.splice.apply(arrData, [i, 1].concat(replaceArr));
            i--;
            _all && (i = i + replaceArr.length);
          }
          if (!_all) {
            break;
          }
        }
        obj &&
          typeof obj == "object" &&
          this.deleteOrFindArr(m, obj, children, _all, find, replaceArr);
      }
    } else if (arrData && typeof arrData == "object") {
      Object.prototype.toString.call(arrData[children]) === "[object Array]" &&
        this.deleteOrFindArr(
          arrData[children],
          obj,
          children,
          _all,
          find,
          replaceArr
        );
    }
    return find;
  },
  //合并对象只保留默认字段
  mergeDefault: function () {
    var f = {},
      m = arguments.length,
      o = arguments[m - 1],
      b = 0,
      k,
      h,
      d,
      g;
    if (typeof o == "boolean") {
      b++;
      h = o;
    }
    if (Object.prototype.toString.call(arguments[0]) === "[object Array]") {
      d = true;
      g = arguments[0];
      k = arguments[1];
    } else {
      k = arguments[0] || {};
    }
    for (var c = 0; c < m - b; c++) {
      var a = arguments[c];
      for (var e in d ? g : k) {
        var n = d ? g[e] : e;
        if (a && a[n] !== undefined) {
          h ? (f[n] = a[n]) : (k[n] = a[n]);
        } else {
          h && c == 0 && (f[n] = k[n]);
        }
      }
    }
    return h ? f : k;
  },
};
